import React, { useEffect } from "react";
import { Box, Typography, Grid, Card, Button, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SupplierFactoryRemediationStatus from "./supplierFactoryRemediationStatus.component";
import { selectFactoryListBySupplierId } from "../../selectors/factory.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";

const SupplierSupplyChainOverview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const factories = useSelector((state) => 
    selectFactoryListBySupplierId(state, params.supplierId)
  );
  
  useEffect(() => {
    dispatch(
      getListOfAllFactories({
        supplierId: params.supplierId,
        supplierFactoryList: true
      })
    );
  }, [dispatch, params.supplierId]);

  const renderTier1Count = () => {
    const tier1Factories = factories.filter(f => f?.metadata?.tier === "1");
    
    const statuses = ["Approved", "Pending", "Inactive"];
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {statuses.map(status => {
          const count = tier1Factories.filter(f => f?.status === status).length;
          return (
            <Box key={status} sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2
            }}>
              <Typography
                sx={{
                  color: "#344054",
                  fontSize: "36px",
                  fontWeight: 600,
                  width: "48px",
                  textAlign: "right"
                }}
              >
                {count}
              </Typography>
              <Typography
                sx={{
                  color: "#667085",
                  fontSize: "18px",
                  fontWeight: 500
                }}
              >
                {status}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 3, mt: 4 }}>
        Factory Count
      </Typography>
      <Card
        sx={{
          py: 4,
          px: 4,
          borderRadius: "12px",
          border: "1px solid #D0D5DD",
          background: "#FFF",
          boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Typography
              sx={{
                color: "#344054",
                fontSize: "18px",
                fontWeight: 700,
                textAlign: "center",
                mb: 2
              }}
            >
              Total
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ 
            borderLeft: '1px solid #EAECF0',
            borderRight: '1px solid #EAECF0'
          }}>
            <Typography
              sx={{
                color: "#344054",
                fontSize: "18px",
                fontWeight: 700,
                mb: 2,
                textAlign: "center"
              }}
            >
              Tier 1
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                color: "#344054",
                fontSize: "18px",
                fontWeight: 700,
                textAlign: "center",
                mb: 2
              }}
            >
              Tier 2
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 3 }} />
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "12px",
                  background: "#2D31A6",
                  color: "#fff",
                  fontSize: "36px",
                  fontWeight: 700,
                  padding: "8px 35px",
                  minWidth: "120px",
                  "&:hover": {
                    background: "#2D31A6"
                  }
                }}
              >
                {factories.length || "-"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ 
            borderLeft: '1px solid #EAECF0',
            borderRight: '1px solid #EAECF0'
          }}>
            {renderTier1Count()}
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
              <Typography
                sx={{ 
                  color: "#344054", 
                  fontSize: "36px", 
                  fontWeight: 600
                }}
              >
                {factories.filter(f => f?.metadata?.tier === "2").length || "-"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <SupplierFactoryRemediationStatus />
    </Box>
  );
};

export default SupplierSupplyChainOverview;
